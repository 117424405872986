import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "accounting",
})
export class Accounting implements PipeTransform {
  transform(value: any): any {
    if (value == "" || value == null || value == undefined) {
      return value;
    }
    var formatter = new Intl.NumberFormat("en-US", { 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
     });
    return formatter.format(value); 
  }
}
