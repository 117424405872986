import { CartService, CartDRAFT } from '../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../../services/database.service'
import { DatePipe } from '@angular/common';
import { HttpService } from '../../services/http.service'


@Component({
  selector: 'app-addreturn',
  templateUrl: './addreturn.page.html',
  styleUrls: ['./addreturn.page.scss']
})

export class AddreturnPage implements OnInit {
  paidqty: number = 0;
  promoqty: number = 0;
  lndisc: number = 0;
  lnprc: number = 0;
  cartdraft: CartDRAFT;
  currentdate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
  carttotalFromItems: number = 0;
  carttotal: number = 0;
  currentCustomerMonthlyDeliveryTotal: number = 0;
  currentCustomerLastYearInvoiceTotal: number = 0;
  currentCustomerPurchaseType: string = "";


  constructor(
    public datepipe: DatePipe,
    public sqlservice: DatabaseService,
    public http: HttpService,
    public cartService: CartService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.getTotal();
  }
  decreaseCartItemSP(product) {
    this.cartService.decreaseProduct(product);
    this.sumCustomTotal();
    //console.log('1')
  }
  increaseCartItemSP(product) {
    this.cartService.addProduct(product);
    this.sumCustomTotal();
    //console.log('2')
  }

  decreaseCartItemDP(product) {
    this.cartService.decreaseProduct(product);
    this.getTotal();
    //console.log('1')
  }
  increaseCartItemDP(product) {
    this.cartService.addProduct(product);
    this.getTotal();
    //console.log('2')
  }
  addCartCustomer(customer) {
    this.cartService.addCustomer(customer);
    this.getTotal();
    //console.log('3')
  }
  removeCartCustomer() {
    this.cartService.removeCustomer();
    this.getTotal();
    //console.log('4')
  }
  removeCartItem(product) {
    this.cartService.removeProduct(product);
    this.getTotal();
    //console.log('5')
  }

  sumCustomTotal() {
    for (var i = 0; i < this.cartService.cartP.length; ++i) {
      this.cartService.cartP[i].linetotal = this.cartService.cartP[i].pprice * this.cartService.cartP[i].amount;
    }
    this.carttotalFromItems = this.cartService.cartP.reduce((i, j) => i + j.linetotal, 0);
    this.carttotal = this.carttotalFromItems;
  }


  getTotal() {
    for (var i = 0; i < this.cartService.cartP.length; ++i) {
      this.cartService.cartP[i].linetotal = this.cartService.cartP[i].pprice * this.cartService.cartP[i].amount;
    }
    this.carttotalFromItems = this.cartService.cartP.reduce((i, j) => i + j.linetotal, 0);
    this.carttotal = this.carttotalFromItems - (this.carttotalFromItems * this.cartService.cartDiscount / 100);
  }

  async close() {
    const modal3 = await this.modalCtrl.getTop();
    modal3.dismiss().then(() => { this.cartService.refreshcartcount() });
    this.sqlservice.getRows('6');
  }

  async returnCartToDraft(form) {
    const modal = await this.modalCtrl.getTop();
    this.cartdraft = {};
    if (this.cartService.cartSourceType == 'Delivery' || this.cartService.cartSourceType == 'ReturnDraft') {
      this.cartdraft = {
        customer: this.cartService.cartC,
        products: this.cartService.cartP,

        prepaid: this.cartService.cartDiscountStatus ? 'Y' : 'N',
        discount: this.cartService.cartDiscount,

        date: this.currentdate,
        tx_status: "Драфт",

        stype: this.cartService.cartSalesType,
        pterm: this.cartService.cartPaymentTerm,
        rtype: this.cartService.cartReturnType,
        srctype: this.cartService.cartSourceType,

        compactview: this.cartService.cartCompactView ? 'Y' : 'N',

        spcode: this.cartService.cartSpcode,
        dpcode: this.cartService.cartDpcode,
        owner: this.cartService.cartOwnercode,

        basedelivery: this.cartService.cartBaseDelivery,

        total: this.carttotal
      };
      this.http.uploadReturnNote(this.cartService.cartBaseDelivery, 'Y');
    }
    else {
      this.cartdraft = {
        customer: this.cartService.cartC,
        products: this.cartService.cartP,

        prepaid: this.cartService.cartDiscountStatus ? 'Y' : 'N',
        discount: this.cartService.cartDiscount,

        date: this.currentdate,
        tx_status: "Драфт",

        stype: this.cartService.cartSalesType,
        pterm: this.cartService.cartPaymentTerm,
        rtype: this.cartService.cartReturnType,
        srctype: this.cartService.cartSourceType,

        compactview: this.cartService.cartCompactView ? 'Y' : 'N',

        spcode: this.sqlservice.auth_data.spcode,
        dpcode: this.sqlservice.auth_data.code,
        owner: this.sqlservice.auth_data.code,

        basedelivery: this.cartService.cartBaseDelivery,

        total: this.carttotal
      };
    }



    //console.log(this.cartdraft);
    modal.dismiss(this.cartdraft)
    this.sqlservice.getRows('3');
    this.cartService.cartC = {};
    this.cartService.cartP = [];
    this.cartService.cartDiscount = 0;
    this.cartService.cartSourceType = "";
    this.cartService.cartItemCount.next(this.cartService.cartItemCount.value - this.cartService.cartItemCount.value);
  }
}
