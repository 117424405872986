import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { Accounting } from './pipes/accounting.pipe';
import { GroupbyPipeDate } from './pipes/groupbydate.pipe';
import { GroupbytimePipe } from './pipes/groupbytime.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        Accounting,GroupbyPipeDate,GroupbytimePipe
    ],
    exports: [
        Accounting,GroupbyPipeDate,GroupbytimePipe
    ],

})
export class SharedModule {}