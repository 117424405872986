import { Injectable } from "@angular/core";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { DatabaseService } from "./database.service";
import { Subscription } from "rxjs/Subscription";
import { filter } from "rxjs/operators";
import { BluetoothLE } from "@ionic-native/bluetooth-le/ngx";
import { AlertController , Platform} from "@ionic/angular";
import { DatePipe } from "@angular/common";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  positionSubscription: Subscription;
  constructor(
    private geolocation: Geolocation,
    private http: HttpService,
    private sqlservice: DatabaseService,
    private bluetoothle: BluetoothLE,
    private alertCtrl: AlertController,
    private datePipe: DatePipe,
    private platform: Platform
  ) {}

  initGPS() {
     this.sqlservice.getRows('93').then(() => {
      if (this.sqlservice.tracking_state.value == true) {
        this.initTracking();
      } else {
        //this.uninitTracking();
        return;
      }
    });
  }

  lastUpdateTime: any;
  watchOptions = {
    timeout: 60 * 1000,
    maxAge: 0,
    enableHighAccuracy: true,
  };

  getLocation() {
    return new Promise<any>((resolve, reject) => {
      this.geolocation
        .getCurrentPosition()
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          //console.log("Error getting location", error);
          reject(error);
        });
    });
  }

  initTracking() {
    this.sqlservice.insertRow('93', { value: false });
    if (this.platform.is('android')){
      this.bluetoothle.hasPermission().then((perm_res) => {
        if (perm_res.hasPermission) {
          this.bluetoothle.isLocationEnabled().then((location_res) => {
            if (location_res.isLocationEnabled) {
              this.startTracking();
            } else {
              this.bluetoothle.requestLocation().then((location_res) => {
                if (location_res.requestLocation) {
                  this.startTracking();
                } else {
                  //this.initTracking();
                }
              });
              this.showPrompt();
            }
          });
        } else {
          this.bluetoothle.requestPermission().then((perm_res) => {
            if (perm_res.requestPermission) {
              this.bluetoothle.isLocationEnabled().then((location_res) => {
                if (location_res.isLocationEnabled) {
                  this.startTracking();
                } else {
                  this.bluetoothle.requestLocation().then((location_res) => {
                    if (location_res.requestLocation) {
                      this.startTracking();
                    } else {
                      //this.initTracking();
                    }
                  });
                  this.showPrompt();
                }
              });
            } else {
              //this.initTracking();
            }
          });
        }
      });
    }
    else if (this.platform.is('ios')){
      this.startTracking();
    }
  }

  async showPrompt() {
    const alert = await this.alertCtrl.create({
      cssClass: "location-css",
      header: "GPS тэмдэглэл",
      message:
        "Зөвхөн аппыг ашиглаж байх үед 15 секундын зайтай байршлын мэдээлэл хөтөлдөг тул төхөөрөмжийн GPS-ийг асаах шаардлагатай",
      buttons: [
        {
          text: "Асаах",
          handler: () => {
            this.initTracking();
          },
        },
        {
          text: "GPS унтраатай чигээр ашиглах",
          role: "cancel",
          handler: () => {
            this.sqlservice.insertRow('93', { value: false });
          },
        },
      ],
    });
    alert.present();
  }

  uninitTracking() {
    this.sqlservice.insertRow('93', { value: false });
    this.positionSubscription.unsubscribe();
  }

  startTracking() {
    this.sqlservice.insertRow('93', { value: true });
    this.positionSubscription = this.geolocation
      .watchPosition(this.watchOptions)
      .pipe(filter((p) => p.coords !== undefined))
      .subscribe((data) => {
        var now = new Date();
        if (
          this.lastUpdateTime &&
          now.getTime() - this.lastUpdateTime.getTime() <
            this.sqlservice.auth_data?.gps_update_rate * 1000
        ) {
          //console.log("Ignoring position update");
          return;
        }
        this.lastUpdateTime = now;

        let newRoute = {
          user: this.sqlservice.auth_data?.code,
          date: this.datePipe.transform(data.timestamp, "yyyy-MM-dd"),
          time: data.timestamp,
          lat: data.coords.latitude,
          lng: data.coords.longitude,
        };
        if(newRoute.user != null){
          this.sqlservice.getRows('92').then(() => {
            this.http.sendGPSdata(newRoute);
            this.sqlservice.gpslog.push(newRoute);
            this.sqlservice.insertRow('92', this.sqlservice.gpslog);
          });
        }
      });
  }
}
