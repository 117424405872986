import { Injectable } from "@angular/core";
import { LoadingService } from "./loading.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { DatabaseService } from "./database.service";
import { AuthenticationService } from "../services/auth.service";
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})

export class HttpService {
  
  base_path = "https://api.nanodis.mn/v7";

  constructor(
    private authService: AuthenticationService,
    private loadingController: LoadingService,
    private httpClient: HttpClient,
    private sqlservice: DatabaseService,
    private alertController: AlertController
  ) {}

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.loadingController.hideLoader();
      this.loadingController.presentToast("Алдаа:" + error.error.message);
    } else if (error.status && error.statusText) {
      this.loadingController.hideLoader();
      this.loadingController.presentToast(
        "Алдаа:" + error.status + ". " + error.statusText
      );
    } else {
      this.loadingController.hideLoader();
      this.loadingController.presentToast(
        "Дата, Wifi, интернет сүлжээгээ шалгана уу"
      );
    }
  }

  async login(key) {
    //this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post(
          this.base_path + "/login",
          { userkey: key },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (authdata: any[]) => {
            resolve(authdata);
            if (authdata["status"] === "loginfail") {
              this.loadingController.presentToast("Код буруу байна");
            } else {
              this.loadingController.hideLoader();
              this.sqlservice.insertRow('0', authdata).then(() => {
                this.authService.authenticationState.next(true);
              });
              //this.req2fa(authdata);
            }
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  async req2fa(authdata) {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      let formData = new FormData();
      formData.append("recipient", "976" + authdata.telephone);
      formData.append("originator", "NanoDis");
      formData.append("type", "sms");
      this.httpClient
        .post("https://rest.messagebird.com/verify", formData, {
          headers: {
            //Authorization: "AccessKey vup9UnOqjVF8ludUFBumEcWLz",
            Authorization: "AccessKey WbIdvoVT9MR8xvU5ZGqypnXC5",
          },
        })
        .timeout(90000)
        .subscribe(
          (res: any[]) => {
            resolve(res);
            this.loadingController.hideLoader();
            this.showOTPinput(authdata, res);
          },
          (err) => {
            this.loadingController.hideLoader();
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  async showOTPinput(authdata, res) {
    const alert = await this.alertController.create({
      //cssClass: 'my-custom-class',
      header: "Сайн байна уу, " + authdata.name,
      message:
        "Таны албаны " +
        authdata.telephone +
        " дугаарт мессежээр очсон баталгаажуулах кодыг оруулна уу",
      backdropDismiss: false,
      animated: true,
      inputs: [
        {
          name: "OTP",
          type: "text",
          placeholder: "нууц дугаар",
          //cssClass: "otpinput",
        },
      ],
      buttons: [
        // {
        //   text: "Хаах",
        //   role: "cancel",
        //   cssClass: "secondary",
        //   handler: () => {
        //     //console.log('Confirm Cancel');
        //   },
        // },
        {
          text: "Баталгаажуулах",
          handler: (data) => {
            this.validate2fa(authdata, res, data.OTP);
          },
        },
      ],
    });
    await alert.present();
  }

  validate2fa(authdata, res, otp) {
    return new Promise<any>((resolve, reject) => {
      let urlSearchParams = new URLSearchParams();
      urlSearchParams.append("token", otp);
      this.httpClient
        .get(
          "https://rest.messagebird.com/verify/" +
            res.id +
            "?" +
            urlSearchParams.toString(),
          {
            headers: {
              //Authorization: "AccessKey vup9UnOqjVF8ludUFBumEcWLz",
              Authorization: "AccessKey WbIdvoVT9MR8xvU5ZGqypnXC5",
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (res: any[]) => {
            resolve(res);
            if (res["status"] == "verified") {
              this.sqlservice.insertRow('0', authdata).then(() => {
                this.authService.authenticationState.next(true);
              });
            } else {
              this.loadingController.presentToast("Код буруу байна");
            }
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  getPBIAccessToken(dashboard) {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/powerbi/get_powerbi_embed_info",
          { dashboard_name: dashboard },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response) => {
            resolve(response);
            this.loadingController.hideLoader();
          },
          (err) => {
            this.loadingController.hideLoader();
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  refreshCustomers() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/ocrd/fetch_customers",
          '{"code":"' + this.sqlservice.auth_data.code + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            let jsonv = JSON.parse(response)
            console.log(jsonv)
            this.sqlservice.insertRow('2', jsonv).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  // refreshItemsAndDeliveryHistory() {
  //   this.loadingController.showLoader();
  //   this.refreshItems().then(() => {
  //     this.receiveMonthlyDeliveries().then(() => {
  //       this.receiveLastYearInvoices().then(() => {
  //         this.loadingController.hideLoader();
  //       });
  //     });
  //   });
  // }

  refreshItems() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/oitm/fetch_items",
          '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            let jsonv = JSON.parse(response);
            if (Array.isArray(jsonv)) {
              jsonv.filter((rs) => (rs.amount = 1));
              jsonv.filter((rs) => (rs.lineprice = 0));
              jsonv.filter((rs) => (rs.linediscount = 0));
              jsonv.filter((rs) => (rs.linedocumentdiscount = 0));
              jsonv.filter((rs) => (rs.linetotal = 0));
              jsonv.filter((rs) => (rs.price = 0));
              jsonv.filter((rs) => (rs.paid = 0));
              jsonv.filter((rs) => (rs.promo = 0));
              jsonv.filter((rs) => (rs.expdate = ""));
              jsonv.filter(
                (rs) => (rs.selectedwh = this.sqlservice.auth_data.defaultwh)
              );
              this.sqlservice.insertRow('3', jsonv);
              this.loadingController.hideLoader();
            }
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  sendSalesDrafts() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.sqlservice.drafts_selected = [];
      let i = this.sqlservice.drafts_data.length;
      let j = this.sqlservice.drafts_selected;
      let k = this.sqlservice.drafts_data;
      while (i--) {
        let l = this.sqlservice.drafts_data[i];
        if (
          Object.keys(l.customer).length &&
          l.products.length > 0 &&
          l.selected &&
          l.tx_status !== "Илгээгдсэн"
        ) {
          for (var n = 0; n < l.products.length; ++n) {
            delete l.products[n]["discounts"];
            delete l.products[n]["docdiscounts"];
            delete l.products[n]["pricelists"];
            delete l.products[n]["stocks"];
            delete l.products[n]["st"];
          }
          j.push(l);
          k.splice(i, 1);
        }
        this.sqlservice.insertRow('4', k);
      }
      this.httpClient
        .post(
          this.base_path + "/ordr/insert_sales_order",
          JSON.stringify(this.sqlservice.drafts_selected),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response) => {
            resolve(response);
            this.loadingController.hideLoader();
          },
          (err) => {
            let m = this.sqlservice.drafts_selected.length;
            while (m--) {
              let n = this.sqlservice.drafts_selected[m];
              k.push(n);
              this.sqlservice.drafts_selected.splice(m, 1);
            }
            this.sqlservice.insertRow('4', k).then(() => {
              this.loadingController.hideLoader();
            });
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveReturns() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/ordn/fetch_returns",
          '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('7', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveReturnRequests() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.sqlservice.return_processed = [];
      this.httpClient
        .put(
          this.base_path + "/orrr/fetch_return_requests",
          '{"requesterId":"' + this.sqlservice.auth_data.code + '","requesterType":"' + this.sqlservice.auth_data.type + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            let k = JSON.parse(response);
            for (var index = 0; index < k.length; index++) {
              let j = JSON.parse(k[index].json);
              j.tx_status = k[index].syncstatus;
              j.description = k[index].description;
              this.sqlservice.return_processed.push(j);
            }
            this.sqlservice
              .insertRow('16', this.sqlservice.return_processed)
              .then(() => {
                this.loadingController.hideLoader();
              });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  sendReturnsDrafts() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.sqlservice.return_drafts_selected = [];
      let i = this.sqlservice.return_drafts_data.length;
      let j = this.sqlservice.return_drafts_selected;
      let k = this.sqlservice.return_drafts_data;
      while (i--) {
        let l = this.sqlservice.return_drafts_data[i];
        if (
          Object.keys(l.customer).length &&
          l.products.length > 0 &&
          l.selected &&
          l.tx_status !== "Илгээгдсэн"
        ) {
          for (var n = 0; n < l.products.length; ++n) {
            delete l.products[n]["discounts"];
            delete l.products[n]["docdiscounts"];
            delete l.products[n]["pricelists"];
            delete l.products[n]["stocks"];
            delete l.products[n]["st"];
          }
          j.push(l);
          k.splice(i, 1);
        }
        this.sqlservice.insertRow('15', k);
      }
      this.httpClient
        .post(
          this.base_path + "/orrr/insert_return_request",
          JSON.stringify(this.sqlservice.return_drafts_selected),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response) => {
            resolve(response);
            this.loadingController.hideLoader();
          },
          (err) => {
            let m = this.sqlservice.return_drafts_selected.length;
            while (m--) {
              let n = this.sqlservice.return_drafts_selected[m];
              k.push(n);
              this.sqlservice.return_drafts_selected.splice(m, 1);
            }
            this.sqlservice.insertRow('15', k).then(() => {
              this.loadingController.hideLoader();
            });
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveOrders() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.sqlservice.drafts_processed = [];
      this.httpClient
        .put(
          this.base_path + "/ordr/fetch_sales_orders",
          '{"spcode":"' + this.sqlservice.auth_data.spcode + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            let k = JSON.parse(response);
            for (var index = 0; index < k.length; index++) {
              let j = JSON.parse(k[index].json);
              j.tx_status = k[index].syncstatus;
              j.description = k[index].description;
              this.sqlservice.drafts_processed.push(j);
            }
            this.sqlservice
              .insertRow('10', this.sqlservice.drafts_processed)
              .then(() => {
                this.loadingController.hideLoader();
              });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  // receiveMonthlyDeliveries() {
  //   return new Promise<any>((resolve, reject) => {
  //     this.httpClient
  //       .put(
  //         this.base_path + "/odln/fetch_deliveries_this_month",
  //         '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
  //           },
  //         }
  //       )
  //       .timeout(10000)
  //       .subscribe(
  //         (response: string) => {
  //           resolve(response);
  //           let jsonv = JSON.parse(response)
  //           if (Array.isArray(jsonv)) {
  //             this.sqlservice.insertRow(13, JSON.parse(response));
  //           }
  //         },
  //         (err) => {
  //           this.handleError(err);
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  // receiveLastYearInvoices() {
  //   return new Promise<any>((resolve, reject) => {
  //     this.httpClient
  //       .put(
  //         this.base_path + "/oinv/fetch_invoices_last_year",
  //         '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
  //           },
  //         }
  //       )
  //       .timeout(90000)
  //       .subscribe(
  //         (response: string) => {
  //           resolve(response);
  //           this.sqlservice.insertRow(14, JSON.parse(response));
  //         },
  //         (err) => {
  //           this.handleError(err);
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  receiveInvoices() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/oinv/fetch_invoices",
          '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code +  '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('8', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveInvoicesLM() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/oinv/fetch_invoices_last_month",
          '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('8', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveDeliveries() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/odln/fetch_deliveries",
          '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('6', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveCmemos() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/orin/fetch_credit_memos",
          '{"requesterType":"' + this.sqlservice.auth_data.type + '","requesterId":"' + this.sqlservice.auth_data.code + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('17', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  uploadDeliveryNotes(docentry, dstatus, pstatus, memo) {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post(
          this.base_path + "/odln/insert_deivery_order_note",
          '{"docentry":"' + docentry + '","pstatus":"' + pstatus + '","status":"' + dstatus + '","memo":"' + memo + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: any[]) => {
            resolve(response);
            for (
              var index = 0;
              index < this.sqlservice.deliveries_data.length;
              index++
            )
              if (
                this.sqlservice.deliveries_data[index].docentry === docentry
              ) {
                this.sqlservice.deliveries_data[index].dstatus = dstatus;
                this.sqlservice.deliveries_data[index].pstatus = pstatus;
                this.sqlservice.deliveries_data[index].dnote = memo;
              }
            this.sqlservice
              .insertRow('6', this.sqlservice.deliveries_data)
              .then(() => {
                this.loadingController.hideLoader();
              });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  uploadReturnNote(docentry, val) {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post(
          this.base_path + "/odln/insert_delivery_order_return_note",
          '{"docentry":"' + docentry + '","val":"' + val + '"}',
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(90000)
        .subscribe(
          (response: any[]) => {
            resolve(response);
            for (
              var index = 0;
              index < this.sqlservice.deliveries_data.length;
              index++
            )
              if (
                this.sqlservice.deliveries_data[index].docentry === docentry
              ) {
                this.sqlservice.deliveries_data[index].hasreturn = val;
              }
            this.sqlservice
              .insertRow('6', this.sqlservice.deliveries_data)
              .then(() => {
                this.loadingController.hideLoader();
              });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  //************ WMS  ****************
  receiveOrdersFromSap() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(this.base_path + "/ordr/wms_fetch_sales_orders", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
          },
        })
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('30', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveDeliveriesFromSap() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(this.base_path + "/odln/wms_fetch_deliveries", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
          },
        })
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('31', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveReturnrequestsFromSap() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(this.base_path + "/orrr/wms_fetch_return_requests", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
          }
        })
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('32', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveReturnsFromSap() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(this.base_path + "/ordn/wms_fetch_returns", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
          },
        })
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('33', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveCmemosFromSap() {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(this.base_path + "/orin/wms_fetch_credit_memos", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
          }
        })
        .timeout(90000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('34', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  sendGPSdata(location) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(this.base_path + "/gps/insert_gps_log", location, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
          },
        })
        .timeout(this.sqlservice.auth_data?.gps_update_rate * 1000)
        .subscribe(
          (response) => {
            resolve(response);
            //this.loadingController.hideLoader();
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }
  //////////////////////////////////

  receiveGPSdata(startdate, enddate) {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/gps/fetch_gps_log",
          { startdate: startdate, enddate: enddate },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(900000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('94', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }

  receiveGPSdataPerUser(startdate, enddate,userid) {
    this.loadingController.showLoader();
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .put(
          this.base_path + "/gps/fetch_gps_log_per_user",
          { startdate: startdate, enddate: enddate , userid: userid},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.sqlservice.auth_data.access_token,
            },
          }
        )
        .timeout(900000)
        .subscribe(
          (response: string) => {
            resolve(response);
            this.sqlservice.insertRow('94', JSON.parse(response)).then(() => {
              this.loadingController.hideLoader();
            });
          },
          (err) => {
            this.handleError(err);
            reject(err);
          }
        );
    });
  }
}
