import { CartService, CartDRAFT } from '../../services/cart.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { DatabaseService } from '../../services/database.service'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-addsales',
  templateUrl: './addsales.page.html',
  styleUrls: ['./addsales.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddsalesPage implements OnInit {
  line_price: number = 0;
  paid_qty: number = 0;
  promo_qty: number = 0;
  line_discount: number = 0;
  docdiscount_table: any = [];
  cartdraft: CartDRAFT;
  currentdate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
  carttotal: number = 0;
  constructor(
    public datepipe: DatePipe,
    public sqlservice: DatabaseService,
    public httpClient: HttpClient,
    public cartService: CartService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.getTotal();
  }

  changePaymentTerm(event) {
    this.cartService.cartPaymentTerm = event
    this.getTotal();
  }

  changeSalesType(event) {
    this.cartService.cartSalesType = event
    this.getTotal();
  }

  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
    this.getTotal();
  }

  increaseCartItem(product) {
    this.cartService.addProduct(product);
    this.getTotal();
  }

  addCartCustomer(customer) {
    this.cartService.addCustomer(customer);
    this.getTotal();
  }

  removeCartCustomer() {
    this.cartService.removeCustomer();
    this.getTotal();
  }

  removeCartItem(product) {
    this.cartService.removeProduct(product);
    this.getTotal();
  }

  getTotal() {

    // ХӨНГӨЛӨЛТ УРАМШУУЛАЛГҮЙ ҮЕД
    if (this.cartService.cartDiscountStatus === false && this.cartService.cartP.length > 0 && Object.keys(this.cartService.cartC).length > 0) {
      for (var i = 0; i < this.cartService.cartP.length; ++i) {
        
        // ҮНИЙН ЖАГСААЛТААС ХАРИЛЦАГЧИЙН ҮНИЙГ ОЛЖ ЗООХ
        if (this.cartService.cartP[i].pricelists.length > 0) {
          for (var j = 0; j < this.cartService.cartP[i].pricelists.length; ++j) {
            if (this.cartService.cartP[i].pricelists[j].n === this.cartService.cartC.n && this.cartService.cartP[i].t === "N") {
              this.line_price = Number(this.cartService.cartP[i].pricelists[j].p)
            }
            else if (this.cartService.cartP[i].pricelists[j].n === this.cartService.cartC.n && this.cartService.cartP[i].t === "S") {
              this.line_price = this.cartService.cartP[i].pricelists.filter(item => { return item.n === 999 })[0]?.p;
            }
          }
        }
        
        // ХӨНГӨЛӨЛТ, УРАМШУУЛЛЫГ БАЙХГҮЙ ГЭЖ ТОХИРУУЛАХ
        this.cartService.cartP[i].lineprice = this.line_price;
        this.cartService.cartP[i].linediscount = 0;
        this.cartService.cartP[i].paid = 0;
        this.cartService.cartP[i].promo = 0;
        this.cartService.cartP[i].linedocumentdiscount = 0;
        this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
        this.cartService.cartP[i].pprice = (this.cartService.cartP[i].lineprice - (this.cartService.cartP[i].lineprice * this.cartService.cartP[i].linediscount / 100)) - ((this.cartService.cartP[i].lineprice - (this.cartService.cartP[i].lineprice * this.cartService.cartP[i].linediscount / 100)) * this.cartService.cartP[i].linedocumentdiscount / 100);
        this.cartService.cartP[i].linetotal = this.cartService.cartP[i].pprice * this.cartService.cartP[i].amount;
      }
    }
    
    // ХӨНГӨЛӨЛТ УРАМШУУЛАЛТАЙ ҮЕД
    else if (this.cartService.cartDiscountStatus === true && this.cartService.cartP.length > 0 && Object.keys(this.cartService.cartC).length > 0) {
      for (var i = 0; i < this.cartService.cartP.length; ++i) {
        // ХАРИЛЦАГЧИЙН ҮНЭ ЗООХ
        if (this.cartService.cartP[i].pricelists.length > 0) {
          for (var j = 0; j < this.cartService.cartP[i].pricelists.length; ++j) {
            if (this.cartService.cartP[i].pricelists[j].n === this.cartService.cartC.n) {
              this.line_price = Number(this.cartService.cartP[i].pricelists[j].p)
            }
          }
        }
        
        // БАРААНЫ ХӨНГӨЛӨЛТ, УРАМШУУЛЛЫГ ТООЦООЛОХ
        if (this.cartService.cartP[i].discounts.length > 0) {
          for (var k = 0; k < this.cartService.cartP[i].discounts.length; ++k) {
            if ((this.cartService.cartP[i].discounts[k].code === this.cartService.cartC.ch && this.cartService.cartP[i].discounts[k].type === "D") || (this.cartService.cartP[i].discounts[k].code === this.cartService.cartC.id && this.cartService.cartP[i].discounts[k].type === "D")) {
              this.line_discount = Number(this.cartService.cartP[i].discounts[k].discount)
            }
            else if ((this.cartService.cartP[i].discounts[k].code === this.cartService.cartC.ch && this.cartService.cartP[i].discounts[k].type === "P") || (this.cartService.cartP[i].discounts[k].code === this.cartService.cartC.id && this.cartService.cartP[i].discounts[k].type === "P")) {
              this.paid_qty = Number(this.cartService.cartP[i].discounts[k].paid);
              this.promo_qty = Number(this.cartService.cartP[i].discounts[k].promo);
              this.line_discount = this.promo_qty * Number(100 * (Math.floor(this.cartService.cartP[i].amount / (this.paid_qty + this.promo_qty))) / this.cartService.cartP[i].amount);
            }
          }
        }
        
        // БАРААНЫ МӨР БҮРИЙН ХӨНГӨЛӨЛТ, УРАМШУУЛАЛТАЙ ҮНИЙГ ЗООХ
        this.cartService.cartP[i].lineprice = this.line_price;
        this.cartService.cartP[i].linediscount = this.line_discount;
        this.cartService.cartP[i].paid = this.paid_qty;
        this.cartService.cartP[i].promo = this.promo_qty;
        this.cartService.cartP[i].linedocumentdiscount = 0;
        this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
        this.cartService.cartP[i].pprice = (this.cartService.cartP[i].lineprice - (this.cartService.cartP[i].lineprice * this.cartService.cartP[i].linediscount / 100)) - ((this.cartService.cartP[i].lineprice - (this.cartService.cartP[i].lineprice * this.cartService.cartP[i].linediscount / 100)) * this.cartService.cartP[i].linedocumentdiscount / 100);
        this.cartService.cartP[i].linetotal = this.cartService.cartP[i].pprice * this.cartService.cartP[i].amount;
        
        // ДАРААГИЙН МӨР БАРААНЫ ТООЦООЛОЛД ЗОРИУЛЖ RESET ХИЙХ
        this.line_price = 0;
        this.line_discount = 0;
        this.paid_qty = 0;
        this.promo_qty = 0;
      }

      // БАРААНЫ МӨРИЙН ХӨНГӨЛӨЛТ ТООЦСОНЫ ДАРАА ВЕНДОРЫН ХӨНГӨЛӨЛТ (ХУУЧНААР ПАДААНЫ ХӨНГӨЛӨЛТ) ТООЦООЛОХ 
      this.docdiscount_table = []
      var product_sums = this.cartService.cartP.reduce((r, a) => {
        r[a.vendor_code] = r[a.vendor_code] || [];
        r[a.vendor_code].push(a.linetotal);
        return r;
      }, {});
      if (Array.isArray(this.cartService.cartC.docdiscounts.channel) && this.cartService.cartC.docdiscounts.channel.length > 0) {
        this.cartService.cartC.docdiscounts.channel.forEach((element, index) => {
          var vendor_sums = [];
          Object.keys(product_sums).map(o => {
            if (element.vendors.indexOf(Number(o)) > -1) {
              vendor_sums.push(product_sums[o].reduce((a, b) => a + b, 0))
            }
          });
          const grouped_vendor_sum = (vendor_sums.reduce((a, b) => a + b, 0));
          if (grouped_vendor_sum >= element.min && grouped_vendor_sum < element.max) {
            Object.keys(product_sums).filter(o => {
              if (element.vendors.indexOf(Number(o)) > -1) {
                var product_vendor_based_document_discount = {
                  vendor_code: Number(o),
                  discount_source: element.discount_source,
                  group_based_document_discount: element.discount,
                  item_based_document_discount: 'Энэ талбар барааны мэдээлэлд байна, энд байхгүй. Dummy entry',
                  cash_only_discount: element.cash_only == 'Y' ? true : false
                }
                this.docdiscount_table.push(product_vendor_based_document_discount)
              }
            });
          }
        });
      }
      if (Array.isArray(this.cartService.cartC.docdiscounts.customer) && this.cartService.cartC.docdiscounts.customer.length > 0) {
        this.cartService.cartC.docdiscounts.customer.forEach((element, index) => {
          var vendor_sums = [];
          Object.keys(product_sums).map(o => {
            if (element.vendors.indexOf(Number(o)) > -1) {
              vendor_sums.push(product_sums[o].reduce((a, b) => a + b, 0))
            }
          });
          const grouped_vendor_sum = (vendor_sums.reduce((a, b) => a + b, 0));
          if (grouped_vendor_sum >= element.min && grouped_vendor_sum < element.max) {
            Object.keys(product_sums).filter(o => {
              if (element.vendors.indexOf(Number(o)) > -1) {
                var product_vendor_based_document_discount = {
                  vendor_code: Number(o),
                  discount_source: element.discount_source,
                  group_based_document_discount: element.discount,
                  item_based_document_discount: 'Энэ талбар барааны мэдээлэлд байна, энд байхгүй. Dummy entry',
                  cash_only_discount: element.cash_only == 'Y' ? true : false
                }
                this.docdiscount_table.push(product_vendor_based_document_discount)
              }
            });
          }
        });
      }

      // ВЕНДОРЫН ХӨНГӨЛӨЛТ (ХУУЧНААР ПАДААНЫ ХӨНГӨЛӨЛТ) ЗООХ
      for (var i = 0; i < this.cartService.cartP.length; ++i) {
        for (var k = 0; k < this.docdiscount_table.length; ++k) {
          if (this.cartService.cartP[i].vendor_code == this.docdiscount_table[k].vendor_code) {
            if (this.docdiscount_table[k].discount_source == 'group') {
              if (this.docdiscount_table[k].cash_only_discount == true) {
                if (this.cartService.cartPaymentTerm == "1") {
                  this.cartService.cartP[i].linedocumentdiscount_cash_only = true;
                  this.cartService.cartP[i].linedocumentdiscount = this.docdiscount_table[k].group_based_document_discount;
                }
                else {
                  this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
                  this.cartService.cartP[i].linedocumentdiscount = 0
                }
              }
              else {
                this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
                this.cartService.cartP[i].linedocumentdiscount = this.docdiscount_table[k].group_based_document_discount;
              }
            }
            else if (this.docdiscount_table[k].discount_source == 'item') {
              if (this.docdiscount_table[k].cash_only_discount == true) {
                if (this.cartService.cartPaymentTerm == "1") {
                  this.cartService.cartP[i].linedocumentdiscount_cash_only = true;
                  this.cartService.cartP[i].linedocumentdiscount = this.cartService.cartP[i].item_based_document_discount;
                }
                else {
                  this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
                  this.cartService.cartP[i].linedocumentdiscount = 0
                }
              }
              else {
                this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
                this.cartService.cartP[i].linedocumentdiscount = this.cartService.cartP[i].item_based_document_discount;
              }
            }
            else {
              this.cartService.cartP[i].linedocumentdiscount_cash_only = false;
              this.cartService.cartP[i].linedocumentdiscount = 0
            }
          }
        }
        
        // БАРААНЫ ХӨНГӨЛӨЛТ, УРАМШУУЛАЛТАЙ ҮНЭЭС ВЕНДОРЫН ХӨНГӨЛӨЛТТИЙГ ХАСЧ ЗООХ
        this.cartService.cartP[i].pprice = (this.cartService.cartP[i].lineprice - (this.cartService.cartP[i].lineprice * this.cartService.cartP[i].linediscount / 100)) - ((this.cartService.cartP[i].lineprice - (this.cartService.cartP[i].lineprice * this.cartService.cartP[i].linediscount / 100)) * this.cartService.cartP[i].linedocumentdiscount / 100);
        this.cartService.cartP[i].linetotal = this.cartService.cartP[i].pprice * this.cartService.cartP[i].amount;
      }
    }
    
    // БАРИМТЫН ДҮНГ БҮХ БАРААНЫ ҮНИЙН НИЙЛБЭРЭЭР ГАРГАХ
    this.carttotal = this.cartService.cartP.reduce((i, j) => i + j.linetotal, 0);
  }

  async close() {
    const modal = await this.modalCtrl.getTop();
    modal.dismiss().then(() => { this.cartService.refreshcartcount() });
  }

  async salesCartToDraft(form) {
    const modal = await this.modalCtrl.getTop();
    this.cartdraft = {};
    this.cartdraft = {
      customer: this.cartService.cartC,
      products: this.cartService.cartP,
      prepaid: this.cartService.cartDiscountStatus ? 'Y' : 'N',
      discount: this.cartService.cartDiscount,
      spcode: this.sqlservice.auth_data.spcode,
      dpcode: this.sqlservice.auth_data.code,
      owner: this.sqlservice.auth_data.code,
      date: this.currentdate,
      tx_status: "Драфт",
      stype: this.cartService.cartSalesType,
      pterm: this.cartService.cartPaymentTerm,
      rtype: this.cartService.cartReturnType,
      srctype: this.cartService.cartSourceType,
      compactview: this.cartService.cartCompactView ? 'Y' : 'N',
      dnote: this.cartService.cartNoteFromDP,
      dstatus: this.cartService.cartStatusFromDP,
      pstatus: this.cartService.cartPaymentStatusFromDP,
      total: this.carttotal
    }
    modal.dismiss(this.cartdraft);
    this.sqlservice.getRows('3');
    this.cartService.cartC = {};
    this.cartService.cartP = [];
    this.cartService.cartDiscount = 0;
    this.cartService.cartSourceType = "";
    this.cartService.cartItemCount.next(this.cartService.cartItemCount.value - this.cartService.cartItemCount.value);
  }

  discountToggle() {
    this.getTotal()
  }
}
