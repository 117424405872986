import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export interface CartDRAFT {
  customer?: CartCustomer;
  products?: CartProducts[];

  prepaid?: string;
  discount?: number;

  spcode?: string;
  dpcode?: string;
  owner?: string;

  date?: string;
  tx_status?: string;

  stype?: string;
  pterm?:string;
  rtype?: string;
  srctype?: string;

  compactview?: string;

  dnote?: string;
  dstatus?: string;
  pstatus?: string;
  total?: number;

  // Түгээгч буцаалт шивэх үед
  docentry?: number;
  hasreturn?: string;
  basedelivery?:number;

}
export interface CartCustomer {

  GROUPCODE?: number;
  FOO?: number;
  HOU?: number;
  NIV?: number;
  PVM?: number;
  SCH?: number;
  UFO?: number;
  UHO?: number;
  TERRITORY?: number;
  addr?: string;
  ch?: string;
  id?: string;
  lat?: string;
  long?: string;
  tel1?: string;
  tel2?: string;
  email?: string;
  n?: number;
  name?: string;
  fname?: string;
  nb1?: string;
  nb2?: string;
  reg?: string;
  ter?: string;
  cd?: string;
  cdisc?: number;
  pdisc?: number;
  mdisc?: number;
  ucat?: string;
  docdiscounts?: {
    channel?: any[];
    customer?: any[]
  };
}
export interface CartProducts {
  amount?: number;
  barcode?: string;
  discounts?: any[];
  id?: string;
  item_based_document_discount?: number;
  linediscount?: number;
  linedocumentdiscount? :number;
  linedocumentdiscount_cash_only? :boolean;
  lineprice?: number;
  linetotal?: number;
  name?: string;
  paid?: number;
  pprice?: number;
  price?: number;
  pricelists?: any[];
  promo?: number;
  selectedwh?: string;
  st?: number;
  stocks?: any[];
  t?: string;
  vendor_code?: number;
  vendor_name?: string;
  expdate?: string;

  //tugeegch butsaalt shivehed

  baseentry?: number;
  basetype?: number;


  //ht butsaalt shivehed
  rlinetotal?: number;
}

@Injectable({
  providedIn: 'root'
})


export class CartService {

  public cartC: CartCustomer = {}; //+
  public cartP: CartProducts[] = []; //+
  public cartDiscountStatus: boolean = true; //+
  public cartDiscount: number = 0; //+
  public cartPaymentTerm: string = "1"; //+
  public cartSalesType: string = "1"; //+
  public cartReturnType: string = "2"; //+
  public cartSourceType: string = ""; //+
  public cartCompactView: boolean = false; //+
  public cartItemCount = new BehaviorSubject(0); // shaardlagagui



  public cartStatusFromDP: string = ""; //+
  public cartPaymentStatusFromDP: string = ""; //+
  public cartNoteFromDP: string = ""; //+

  public cartSpcode: string = "";
  public cartDpcode: string = "";
  public cartOwnercode: string = "";


  public cartDeliveryDocEntry: number =0;
  public cartHasReturn: string ="";
  public cartBaseDelivery: number = 0;

  constructor() { }

  getCartItemCount() {
    return this.cartItemCount;
  }

  addProduct(product) {
    let added = false;
    for (let p of this.cartP) {
      if (p.id === product.id) {
        p.amount++;
        added = true;
        break;
      }
    }
    if (!added) {
      this.cartP.push(product);
    }
    this.cartItemCount.next(this.cartItemCount.value + 1);
  }


  removeProduct(product) {
    for (let [index, p] of this.cartP.entries()) {
      if (p.id === product.id) {
        this.cartItemCount.next(this.cartItemCount.value - p.amount);
        this.cartP.splice(index, 1);
        p.amount = 1;
      }
    }
  }

  decreaseProduct(product) {

    for (let [index, p] of this.cartP.entries()) {
      if (p.id === product.id) {
        p.amount--;
        if (p.amount == 0) {
          this.cartP.splice(index, 1);
        }
      }
    }
    this.cartItemCount.next(this.cartItemCount.value - 1);
  }

  addCustomer(customer) {
    this.cartC = customer;
  }

  removeCustomer() {
    this.cartC = {};
  }

  refreshcartcount() {
    this.cartItemCount.next(this.cartItemCount.value - this.cartItemCount.value);
    for (var i = 0; i < this.cartP.length; ++i) {
      this.cartItemCount.next(this.cartItemCount.value + this.cartP[i].amount)
    }
  }
}
