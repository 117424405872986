import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "groupByTime",
})
export class GroupbytimePipe implements PipeTransform {
  transform(data: any, groupByKey: string) {
    let value = data.sort((userA, userB) => {
      return userB.time - userA.time;
    });
    const events: any[] = [];
    const groupedElements: any = {};

    value.forEach((obj: any) => {
      if (!(obj[groupByKey] in groupedElements)) {
        groupedElements[obj[groupByKey]] = [];
      }
      groupedElements[obj[groupByKey]].push(obj);
    });

    for (let prop in groupedElements) {
      if (groupedElements.hasOwnProperty(prop)) {
        events.push({
          date: prop,
          list: groupedElements[prop],
        });
      }
    }
    return events;
  }
}
