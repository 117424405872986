import { DatabaseService } from "./services/database.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "./services/auth.service";
import { Component, NgZone } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { enableProdMode } from "@angular/core";
import { LocationService } from "./services/location.service";
import { LoadingService } from "./services/loading.service";


enableProdMode();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private gpsservice: LocationService,
    public sqlservice: DatabaseService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private zone: NgZone,
    private loadingService: LoadingService
  ) {
    this.initApp();
  }

  initApp(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.platform.ready().then(() => {
        this.sqlservice.initDatabase().then(() => {
            this.authenticationService.authenticationState.subscribe((state) => {
              if (state) {
                this.sqlservice.getRows('0').then(()=>{
                  //console.log(Date.now());
                  //console.log(this.sqlservice.auth_data.date*1000);
                  if ((this.sqlservice.auth_data.date*1000) + (604800*1000) > Date.now()){
                    if (this.sqlservice.auth_data?.type =='SU'){
                      this.router.navigate(["gpsdashboard"]);
                    }
                    else{
                      this.router.navigate(["members"]);
                    }
                    this.gpsservice.initGPS();
                  }
                  else{
                    this.sqlservice.logout();
                    this.loadingService.presentToast("Та ахин нэвтэрнэ үү");
                    this.router.navigate(["login"]);
                  }
                })
              } else {
                this.router.navigate(["login"]);
              }
            });
        });
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      });
    });
  }
}
