import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = false;
  constructor(
    public loadingController: LoadingController, 
    public toastController: ToastController
    ) { }


  async showLoader() {
    this.isLoading = true;
    return await this.loadingController.create({
      message:  '<ion-img src="/assets/pulse.svg" alt="loading..."></ion-img>',
      translucent: false,
      showBackdrop: false,
      cssClass:'nanodis-loading-class',
      spinner: null,
    }).then((res) => {
      res.present().then(()=>{
        if (!this.isLoading) {
          res.dismiss()
        }
      });
    });
  }

  async hideLoader() {
    this.isLoading = false;
    return await this.loadingController.dismiss();
  }

  async presentToast(message) {
  return await this.toastController.create({
    message: message,
    duration: 2000
    }).then((res)=>{
    res.present();
  });
 }
}
