import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'members',
    loadChildren: () => import('./members/members.module').then(m => m.MembersPageModule),
    canActivate:[AuthGuard]
  },
  {
    path:'wms',
    loadChildren: () => import('./wms/wms.module').then(m => m.WmsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'members/details/:type/:id',
    loadChildren: () => import('./members/details/details.module').then(m => m.DetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'addreturn',
    loadChildren: () => import('./members/addreturn/addreturn.module').then( m => m.AddreturnPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'returns',
    loadChildren: () => import('./members/returns/returns.module').then( m => m.ReturnsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'addsales',
    loadChildren: () => import('./members/addsales/addsales.module').then( m => m.AddsalesPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'editnote',
    loadChildren: () => import('./members/editnote/editnote.module').then( m => m.EditnotePageModule),
    canActivate:[AuthGuard]
  },

  {
    path: 'messagemodal',
    loadChildren: () => import('./shared/messagemodal/messagemodal.module').then( m => m.MessagemodalPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'gps',
    loadChildren: () => import('./shared/gps/gps.module').then( m => m.GpsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'gpsdashboard',
    loadChildren: () => import('./shared/gpsdashboard/gpsdashboard.module').then( m => m.GpsdashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'salesdashboard',
    loadChildren: () => import('./shared/salesdashboard/salesdashboard.module').then( m => m.SalesdashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'deliverydashboard',
    loadChildren: () => import('./shared/deliverydashboard/deliverydashboard.module').then( m => m.DeliverydashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'returndashboard',
    loadChildren: () => import('./shared/returndashboard/returndashboard.module').then( m => m.ReturndashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'invoicedashboard',
    loadChildren: () => import('./shared/invoicedashboard/invoicedashboard.module').then( m => m.InvoicedashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'sellindashboard',
    loadChildren: () => import('./shared/sellindashboard/sellindashboard.module').then( m => m.SellindashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'efficiencydashboard',
    loadChildren: () => import('./shared/efficiencydashboard/efficiencydashboard.module').then( m => m.EfficiencydashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'warehousedashboard',
    loadChildren: () => import('./shared/warehousedashboard/warehousedashboard.module').then( m => m.WarehousedashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'maintainance',
    loadChildren: () => import('./shared/maintainance/maintainance.module').then( m => m.MaintainancePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
