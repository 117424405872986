import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe,DecimalPipe } from '@angular/common';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SharedModule } from './shared/SharedModule'
import { AddreturnPage } from './members/addreturn/addreturn.page';
import { AddsalesPage } from './members/addsales/addsales.page';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
  declarations: [AppComponent,AddsalesPage,AddreturnPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IonicStorageModule.forRoot()
  ],
  providers: [
    BarcodeScanner,
    DatePipe,
    DecimalPipe,

    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BluetoothLE,
    Geolocation,

  ],
  entryComponents:[AddsalesPage,AddreturnPage],
  bootstrap: [AppComponent],
})
export class AppModule {}
