import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { CartService } from "./cart.service";
import { LoadingService } from "./loading.service";
import "rxjs/add/operator/retry";
import "rxjs/add/operator/timeout";
import "rxjs/add/operator/delay";
import "rxjs/add/operator/catch";
import { AuthenticationService } from "./auth.service";
import { AlertController } from "@ionic/angular";
import { BarcodeScanner, BarcodeScannerOptions } from "@ionic-native/barcode-scanner/ngx";
import { Storage } from '@ionic/storage-angular';

declare var window: any;
@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  constructor(
    public loadingController: LoadingService,
    public cartService: CartService,
    public platform: Platform,
    private alertCtrl: AlertController,
    private authenticationService: AuthenticationService,
    private barcodeScanner: BarcodeScanner,
    private storage: Storage
  ) { }

  so_prepaidtotal: number = 0;
  so_postpaidtotal: number = 0;

  do_prepaidtotal: number = 0;
  do_postpaidtotal: number = 0;

  invoice_prepaidtotal: number = 0;
  invoice_prepaid_paidtotal: number = 0;
  invoice_prepaid_duetotal: number = 0;

  invoice_postpaidtotal: number = 0;
  invoice_postpaid_paidtotal: number = 0;
  invoice_postpaid_duetotal: number = 0;

  return_prepaidtotal: number = 0;
  return_postpaidtotal: number = 0;

  encodedqr: any;
  locationNotification: any = [];

  databaseObj: any;
  auth_data: any = [];
  history_data: any = [];

  customer_data: any = [];
  customer_filtered_data: any = [];

  item_data: any = [];
  item_filtered_data: any = [];

  invoices_data: any = [];
  invoices_filtered_data: any = [];
  invoice_doctotal: number = 0;
  invoice_paidtotal: number = 0;
  invoice_duetotal: number = 0;

  deliveries_data: any = [];
  deliveries_filtered_data: any = [];

  today_deliveries_data: any = [];

  detail_data: any = [];

  drafts_data: any = [];
  drafts_selected = [];

  drafts_processed: any = [];
  drafts_processed_filtered_data: any = [];

  fav_items_data: any = [];
  fav_items_list: any = [];
  fav_items_search_list: any = [];
  fav_customers_data: any = [];
  fav_customers_list: any = [];
  fav_customers_search_list: any = [];

  deliveries_this_month: any = [];
  invoices_last_year: any = [];

  returns_data: any = [];
  return_filtered_data: any = [];
  return_drafts_data: any = [];
  return_drafts_selected: any = [];
  return_processed: any = [];
  return_processed_filtered_data: any = [];

  creditmemos_data: any = [];
  creditmemos_filtered_data: any = [];

  readonly database_name: string = "nanodis.db"; // DB name
  readonly table_name: string = "v9"; // Table name

  dodailytotal: number = 0;
  domonthlytotal: number = 0;

  sodailytotal: number = 0;
  somonthlytotal: number = 0;

  return_dailytotal: number = 0;
  return_monthlytotal: number = 0;

  returnrequest_dailytotal: number = 0;
  returnrequest_monthlytotal: number = 0;

  cmemo_dailytotal: number = 0;
  cmemo_monthlytotal: number = 0;

  itemsearchTerm: string = "";
  customersearchTerm: string = "";
  salessearchTerm: string = "";
  returnsearchTerm: string = "";

  ordersfromsap_data: any = [];
  ordersfromsap_data_filtered_data: any = [];

  deliveriesfromsap_data: any = [];
  deliveriesfromsap_data_filtered_data: any = [];

  retreqsfromsap_data: any = [];
  retreqsfromsap_data_filtered_data: any = [];

  returnsfromsap_data: any = [];
  returnsfromsap_data_data_filtered_data: any = [];

  cmemosfromsap_data: any = [];
  cmemosfromsap_data_filtered_data: any = [];

  curdaystart: Date;
  curdayend: Date;
  lastdaypmonth: Date;

  notifications: any = [];

  gpslog: any = [];
  tracking_state: any = {};

  gpsdashboard_data: any = [];

  initDatabase(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.storage.create().then(() => {
        resolve(this.CreateOrLoad('0'));
        resolve(this.CreateOrLoad('1'));
        resolve(this.CreateOrLoad('2'));
        resolve(this.CreateOrLoad('3'));
        resolve(this.CreateOrLoad('4'));
        resolve(this.CreateOrLoad('5'));
        resolve(this.CreateOrLoad('6'));
        resolve(this.CreateOrLoad('7'));
        resolve(this.CreateOrLoad('8'));
        resolve(this.CreateOrLoad('9'));
        resolve(this.CreateOrLoad('10'));
        resolve(this.CreateOrLoad('11'));
        resolve(this.CreateOrLoad('12'));
        resolve(this.CreateOrLoad('13'));
        resolve(this.CreateOrLoad('14'));
        resolve(this.CreateOrLoad('15'));
        resolve(this.CreateOrLoad('16'));
        resolve(this.CreateOrLoad('17'));
        resolve(this.CreateOrLoad('30'));
        resolve(this.CreateOrLoad('31'));
        resolve(this.CreateOrLoad('32'));
        resolve(this.CreateOrLoad('33'));
        resolve(this.CreateOrLoad('34'));
        resolve(this.CreateOrLoad('91'));
        resolve(this.CreateOrLoad('92'));
        resolve(this.CreateOrLoad('93'));
        resolve(this.CreateOrLoad('94'))
      });
    });
  }

  CreateOrLoad(type) {
    this.storage.get(type).then(async (value: any) => {
      if (value == null) {
        await this.storage.set(type, JSON.stringify([]))
      }

    }).finally(() => {
      if (type == '0') {
        this.getRows('0').then(() => {
          console.log(new Date().getTime() / 1000);
          console.log(this.auth_data?.date + 1209600);
          if ("access_token" in this.auth_data && new Date().getTime() / 1000 <= this.auth_data?.date + 1209600) {
            this.authenticationService.authenticationState.next(true);
          } else {
            this.logout()
            //this.authenticationService.authenticationState.next(false);
          }
        });
      }
      else {
        this.getRows(type);
      }
    });
  }

  calcReturnTotal() {
    this.curdaystart = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.return_day_offset || 0)).setHours(0, 0, 0, 0));
    this.curdayend = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.return_day_offset || 0)).setHours(23, 59, 59, 0));
    this.lastdaypmonth = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    this.return_dailytotal = 0;
    this.return_monthlytotal = 0;

    if (this.returns_data != null && this.returns_data.length > 0) {
      for (var i = 0; i < this.returns_data.length; ++i) {
        if (
          new Date(
            new Date(this.returns_data[i].docdate).setHours(0, 0, 0, 0)
          ) >= this.curdaystart
        ) {
          this.return_dailytotal += Number(this.returns_data[i].doctotal);
        } else if (
          new Date(
            new Date(this.returns_data[i].docdate).setHours(0, 0, 0, 0)
          ) >= this.lastdaypmonth &&
          new Date(
            new Date(this.returns_data[i].docdate).setHours(0, 0, 0, 0)
          ) <= this.curdayend
        ) {
          this.return_monthlytotal += Number(this.returns_data[i].doctotal);
        }
      }
    }
  }

  calcInvoiceTotal() {
    this.invoice_doctotal = 0;
    this.invoice_paidtotal = 0;
    this.invoice_duetotal = 0;

    this.invoice_prepaidtotal = 0;
    this.invoice_prepaid_paidtotal = 0;
    this.invoice_prepaid_duetotal = 0;

    this.invoice_postpaidtotal = 0;
    this.invoice_postpaid_paidtotal = 0;
    this.invoice_postpaid_duetotal = 0;

    if (this.invoices_data != null && this.invoices_data.length > 0) {
      for (var i = 0; i < this.invoices_data.length; ++i) {
        this.invoice_doctotal += Number(this.invoices_data[i].doctotal);
        this.invoice_paidtotal += Number(this.invoices_data[i].paid);
        this.invoice_duetotal += Number(this.invoices_data[i].due);

        if (this.invoices_data[i]?.pterm == '1') {
          this.invoice_prepaidtotal += Number(this.invoices_data[i].doctotal)
          this.invoice_prepaid_paidtotal += Number(this.invoices_data[i].paid)
          this.invoice_prepaid_duetotal += Number(this.invoices_data[i].due)
        }
        else if (this.invoices_data[i]?.pterm == '2') {
          this.invoice_postpaidtotal += Number(this.invoices_data[i].doctotal)
          this.invoice_postpaid_paidtotal += Number(this.invoices_data[i].paid)
          this.invoice_postpaid_duetotal += Number(this.invoices_data[i].due)
        }
      }
    }
  }

  calcCmemoTotal() {
    this.curdaystart = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.creditmemo_day_offset || 0)).setHours(0, 0, 0, 0));
    this.curdayend = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.creditmemo_day_offset || 0)).setHours(23, 59, 59, 0));
    this.lastdaypmonth = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    this.cmemo_dailytotal = 0;
    this.cmemo_monthlytotal = 0;

    if (this.creditmemos_data != null && this.creditmemos_data.length > 0) {
      for (var i = 0; i < this.creditmemos_data.length; ++i) {
        if (
          new Date(
            new Date(this.creditmemos_data[i].docdate).setHours(0, 0, 0, 0)
          ) >= this.curdaystart
        ) {
          this.cmemo_dailytotal += Number(this.creditmemos_data[i].doctotal);
        } else if (
          new Date(
            new Date(this.creditmemos_data[i].docdate).setHours(0, 0, 0, 0)
          ) >= this.lastdaypmonth &&
          new Date(
            new Date(this.creditmemos_data[i].docdate).setHours(0, 0, 0, 0)
          ) <= this.curdayend
        ) {
          this.cmemo_monthlytotal += Number(this.creditmemos_data[i].doctotal);
        }
      }
    }
  }

  calcReturnRequestTotal() {
    this.curdaystart = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.returnrequest_day_offset || 0)).setHours(0, 0, 0, 0));
    this.curdayend = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.returnrequest_day_offset || 0)).setHours(23, 59, 59, 0));
    this.lastdaypmonth = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    this.returnrequest_dailytotal = 0;
    this.returnrequest_monthlytotal = 0;

    if (this.return_processed != null && this.return_processed.length > 0) {
      for (var i = 0; i < this.return_processed.length; ++i) {
        if (
          new Date(
            new Date(this.return_processed[i].date).setHours(0, 0, 0, 0)
          ) >= this.curdaystart
        ) {
          this.returnrequest_dailytotal += Number(
            this.return_processed[i].total
          );
        } else if (
          new Date(
            new Date(this.return_processed[i].date).setHours(0, 0, 0, 0)
          ) >= this.lastdaypmonth &&
          new Date(
            new Date(this.return_processed[i].date).setHours(0, 0, 0, 0)
          ) <= this.curdayend
        ) {
          this.returnrequest_monthlytotal += Number(
            this.return_processed[i].total
          );
        }
      }
    }
  }

  calcSalesOrdersTotal() {
    this.curdaystart = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.salesorder_day_offset || 0)).setHours(0, 0, 0, 0));
    this.curdayend = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data.salesorder_day_offset || 0)).setHours(23, 59, 59, 0));
    this.lastdaypmonth = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    this.sodailytotal = 0;
    this.somonthlytotal = 0;
    this.so_postpaidtotal = 0;
    this.so_prepaidtotal = 0;

    if (this.drafts_processed != null && this.drafts_processed.length > 0) {
      for (var i = 0; i < this.drafts_processed.length; ++i) {
        if (
          new Date(
            new Date(this.drafts_processed[i].date).setHours(0, 0, 0, 0)
          ) >= this.curdaystart
        ) {
          this.sodailytotal += Number(this.drafts_processed[i].total);
        } else if (
          new Date(
            new Date(this.drafts_processed[i].date).setHours(0, 0, 0, 0)
          ) >= this.lastdaypmonth &&
          new Date(
            new Date(this.drafts_processed[i].date).setHours(0, 0, 0, 0)
          ) <= this.curdayend
        ) {
          this.somonthlytotal += Number(this.drafts_processed[i].total);

          if (this.drafts_processed[i]?.pterm == '1') {
            this.so_prepaidtotal += Number(this.drafts_processed[i].total)
          }
          else if (this.drafts_processed[i]?.pterm == '2') {
            this.so_postpaidtotal += Number(this.drafts_processed[i].total)
          }

        }
      }
    }
  }
  calcDeliveryTotal() {

    this.curdaystart = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data?.delivery_day_offset || 1)).setHours(0, 0, 0, 0));
    this.curdayend = new Date(new Date(new Date().setDate(new Date().getDate() + this.auth_data?.delivery_day_offset || 1)).setHours(23, 59, 59, 0));
    this.lastdaypmonth = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    this.dodailytotal = 0;
    this.domonthlytotal = 0;

    this.do_prepaidtotal = 0;
    this.do_postpaidtotal = 0;

    if (this.deliveries_data != null && this.deliveries_data.length > 0) {
      for (var i = 0; i < this.deliveries_data.length; ++i) {
        if (
          new Date(
            new Date(this.deliveries_data[i].docdate).setHours(0, 0, 0, 0)
          ) >= this.curdaystart
        ) {
          this.dodailytotal += Number(this.deliveries_data[i].doctotal);
        } else if (
          new Date(
            new Date(this.deliveries_data[i].docdate).setHours(0, 0, 0, 0)
          ) >= this.lastdaypmonth &&
          new Date(
            new Date(this.deliveries_data[i].docdate).setHours(0, 0, 0, 0)
          ) <= this.curdayend
        ) {
          this.domonthlytotal += Number(this.deliveries_data[i].doctotal);

          if (this.deliveries_data[i]?.pterm == '1') {
            this.do_prepaidtotal += Number(this.deliveries_data[i].doctotal)
          }
          else if (this.deliveries_data[i]?.pterm == '2') {
            this.do_postpaidtotal += Number(this.deliveries_data[i].doctotal)
          }

        }
      }
    }
  }
  async getRows(type) {
    return await this.storage.get(type).then((res) => {

      if (type == '0') {
        this.auth_data = [];
        this.auth_data = JSON.parse(res);
      } else if (type == '1') {
        this.history_data = [];
        this.history_data = JSON.parse(res);
      } else if (type == '2') {
        this.customer_data = [];
        this.customer_data = JSON.parse(res);
        this.setFilteredCustomers();
      } else if (type == '3') {
        this.item_data = [];
        this.item_data = JSON.parse(res);
        this.setFilteredItems();
      } else if (type == '4') {
        this.drafts_data = [];
        this.drafts_data = JSON.parse(res);
      } else if (type == '5') {
        this.detail_data = [];
        this.detail_data = JSON.parse(res);
      } else if (type == '6') {
        this.deliveries_data = [];
        this.deliveries_data = JSON.parse(res);
        this.setFilteredSales();
        this.calcDeliveryTotal();
      } else if (type == '7') {
        this.returns_data = [];
        this.returns_data = JSON.parse(res);
        this.setFilteredReturns();
        this.calcReturnTotal();
      } else if (type == '8') {
        this.invoices_data = [];
        this.invoices_data = JSON.parse(res);
        this.setFilteredSales();
        this.calcInvoiceTotal();
      } else if (type == '9') {
        this.today_deliveries_data = [];
        this.today_deliveries_data = JSON.parse(res);
      } else if (type == '10') {
        this.drafts_processed = [];
        this.drafts_processed = JSON.parse(res);
        this.setFilteredSales();
        this.calcSalesOrdersTotal();
      } else if (type == '11') {
        this.fav_items_data = [];
        this.fav_items_data = JSON.parse(res);
        this.setFilteredItems();
      } else if (type == '12') {
        this.fav_customers_data = [];
        this.fav_customers_data = JSON.parse(res);
        this.setFilteredCustomers();
      } else if (type == '13') {
        this.deliveries_this_month = [];
        this.deliveries_this_month = JSON.parse(res);
      } else if (type == '14') {
        this.invoices_last_year = [];
        this.invoices_last_year = JSON.parse(res);
      } else if (type == '15') {
        this.return_drafts_data = [];
        this.return_drafts_data = JSON.parse(res);
      } else if (type == '16') {
        this.return_processed = [];
        this.return_processed = JSON.parse(res);
        this.setFilteredReturns();
        this.calcReturnRequestTotal();
      } else if (type == '17') {
        this.creditmemos_data = [];
        this.creditmemos_data = JSON.parse(res);
        this.setFilteredReturns();
        this.calcCmemoTotal();
      } else if (type == '30') {
        this.ordersfromsap_data = [];
        this.ordersfromsap_data = JSON.parse(res);
      } else if (type == '31') {
        this.deliveriesfromsap_data = [];
        this.deliveriesfromsap_data = JSON.parse(res);
      } else if (type == '32') {
        this.retreqsfromsap_data = [];
        this.retreqsfromsap_data = JSON.parse(res);
      } else if (type == '33') {
        this.returnsfromsap_data = [];
        this.returnsfromsap_data = JSON.parse(res);
      } else if (type == '34') {
        this.cmemosfromsap_data = [];
        this.cmemosfromsap_data = JSON.parse(res);
      } else if (type == '91') {
        this.notifications = [];
        this.notifications = JSON.parse(res);
      } else if (type == '92') {
        this.gpslog = [];
        this.gpslog = JSON.parse(res);
      } else if (type == '93') {
        this.tracking_state = {};
        this.tracking_state = JSON.parse(res);
      } else if (type == '94') {
        this.gpsdashboard_data = [];
        this.gpsdashboard_data = JSON.parse(res);
      }
    })
    // return this.databaseObj
    //   .executeSql(
    //     "SELECT jsondata FROM " + this.table_name + " WHERE pid = ?",
    //     [type]
    //   )
    //   .then((res) => {
    //     if (type === 0) {
    //       this.auth_data = [];
    //       this.auth_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 1) {
    //       this.history_data = [];
    //       this.history_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 2) {
    //       this.customer_data = [];
    //       this.customer_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredCustomers();
    //     } else if (type === 3) {
    //       this.item_data = [];
    //       this.item_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredItems();
    //     } else if (type === 4) {
    //       this.drafts_data = [];
    //       this.drafts_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 5) {
    //       this.detail_data = [];
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 6) {
    //       this.deliveries_data = [];
    //       this.deliveries_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredSales();
    //       this.calcDeliveryTotal();
    //     } else if (type === 7) {
    //       this.returns_data = [];
    //       this.returns_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredReturns();
    //       this.calcReturnTotal();
    //     } else if (type === 8) {
    //       this.invoices_data = [];
    //       this.invoices_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredSales();
    //       this.calcInvoiceTotal();
    //     } else if (type === 9) {
    //       this.today_deliveries_data = [];
    //       this.today_deliveries_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 10) {
    //       this.drafts_processed = [];
    //       this.drafts_processed = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredSales();
    //       this.calcSalesOrdersTotal();
    //     } else if (type === 11) {
    //       this.fav_items_data = [];
    //       this.fav_items_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredItems();
    //     } else if (type === 12) {
    //       this.fav_customers_data = [];
    //       this.fav_customers_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredCustomers();
    //     } else if (type === 13) {
    //       this.deliveries_this_month = [];
    //       this.deliveries_this_month = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 14) {
    //       this.invoices_last_year = [];
    //       this.invoices_last_year = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 15) {
    //       this.return_drafts_data = [];
    //       this.return_drafts_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 16) {
    //       this.return_processed = [];
    //       this.return_processed = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredReturns();
    //       this.calcReturnRequestTotal();
    //     } else if (type === 17) {
    //       this.creditmemos_data = [];
    //       this.creditmemos_data = JSON.parse(res.rows.item(0).jsondata);
    //       this.setFilteredReturns();
    //       this.calcCmemoTotal();
    //     } else if (type === 30) {
    //       this.ordersfromsap_data = [];
    //       this.ordersfromsap_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 31) {
    //       this.deliveriesfromsap_data = [];
    //       this.deliveriesfromsap_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 32) {
    //       this.retreqsfromsap_data = [];
    //       this.retreqsfromsap_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 33) {
    //       this.returnsfromsap_data = [];
    //       this.returnsfromsap_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 34) {
    //       this.cmemosfromsap_data = [];
    //       this.cmemosfromsap_data = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 91) {
    //       this.notifications = [];
    //       this.notifications = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 92) {
    //       this.gpslog = [];
    //       this.gpslog = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 93) {
    //       this.tracking_state = {};
    //       this.tracking_state = JSON.parse(res.rows.item(0).jsondata);
    //     } else if (type === 94) {
    //       this.gpsdashboard_data = [];
    //       this.gpsdashboard_data = JSON.parse(res.rows.item(0).jsondata);
    //     }
    //   });
  }

  async insertRow(type, data) {
    await this.storage.set(type, JSON.stringify(data)).then(() => {
      this.getRows(type);
    });
    // return this.databaseObj
    //   .executeSql(
    //     "UPDATE " + this.table_name + " SET (jsondata) = (?) WHERE pid = ?",
    //     [JSON.stringify(data), type]
    //   )
    //   .then(() => {
    //     this.getRows(type);
    //   });
  }

  insertFavItem(data) {
    let i = this.fav_items_data.length;
    let k = this.fav_items_data;
    k.indexOf(data) === -1 ? k.push(data) : console.log("This item already exists");
    this.insertRow('11', k);
    this.filteredItemFavList();
    this.setFilteredItems();
  }
  removeFavItem(data) {
    let i = this.fav_items_data.lastIndexOf(data);
    let k = this.fav_items_data;
    k.splice(i, 1);
    this.insertRow('11', k);
    this.filteredItemFavList();
    this.setFilteredItems();
  }

  insertFavCustomer(data) {
    let i = this.fav_customers_data.length;
    let k = this.fav_customers_data;
    k.indexOf(data) === -1
      ? k.push(data)
      : console.log("This item already exists");
    this.insertRow('12', k);
    this.filteredCustomerFavList();
    this.setFilteredCustomers();
  }

  removeFavCustomer(data) {
    let i = this.fav_customers_data.lastIndexOf(data);
    let k = this.fav_customers_data;
    k.splice(i, 1);
    this.insertRow('12', k);
    this.filteredCustomerFavList();
    this.setFilteredCustomers();
  }

  filteredCustomerFavList() {
    this.fav_customers_list = [];
    this.fav_customers_list = this.customer_data.filter((f) =>
      this.fav_customers_data.includes(f.id)
    );
    //console.log(this.fav_customers_data);
  }
  filteredItemFavList() {
    this.fav_items_list = [];
    this.fav_items_list = this.item_data.filter((f) =>
      this.fav_items_data.includes(f.id)
    );
    //console.log(this.fav_items_list);
  }
  async deleteRows(type) {
    await this.storage.remove(type)
    await this.storage.set(type, '[]');
  }

  getRowDetail(type, id, filterkey) {
    // this.databaseObj
    //   .executeSql(
    //     "SELECT jsondata FROM " + this.table_name + " WHERE pid = ?",
    //     [type]
    //   )
    //   .then((res) => {
    //     this.detail_data = [];
    //     if (type == 10) {
    //       this.detail_data = this.drafts_processed_filtered_data[id];
    //     } else if (type == 4) {
    //       this.detail_data = this.drafts_data[id];
    //     } else if (type == 15) {
    //       this.detail_data = this.return_drafts_data[id];
    //     } else if (type == 16) {
    //       this.detail_data = this.return_processed_filtered_data[id];
    //     } else if (type == 7) {
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata).filter(
    //         (jsonresults) => {
    //           return jsonresults["docentry"] == id;
    //         }
    //       );
    //     } else if (type == 6) {
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata).filter(
    //         (jsonresults) => {
    //           return jsonresults["docentry"] == id;
    //         }
    //       );
    //     } else if (type == 17) {
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata).filter(
    //         (jsonresults) => {
    //           return jsonresults["docentry"] == id;
    //         }
    //       );
    //     } else if (type == 30) {
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata).filter(
    //         (jsonresults) => {
    //           return jsonresults["docentry"] == id;
    //         }
    //       );
    //     } else if (type == 8) {
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata).filter(
    //         (jsonresults) => {
    //           return jsonresults["docentry"] == id;
    //         }
    //       );
    //     } else {
    //       this.detail_data = JSON.parse(res.rows.item(0).jsondata).filter(
    //         (jsonresults) => {
    //           return jsonresults[filterkey] == id;
    //         }
    //       );
    //     }
    //     this.detail_data["type"] = type;
    //     return this.detail_data;
    //   });
  }

  deleteSalesDrafts() {
    let i = this.drafts_data.length;
    let k = this.drafts_data;
    while (i--) {
      let j = this.drafts_data[i];
      if (j.selected) {
        k.splice(i, 1);
      }
    }
    this.insertRow('4', k);
  }

  deleteSalesDraft(index) {
    let i = this.drafts_data.length;
    let k = this.drafts_data;
    k.splice(index, 1);
    this.insertRow('4', k);
  }

  deleteReturnDraft(index) {
    let i = this.return_drafts_data.length;
    let k = this.return_drafts_data;
    k.splice(index, 1);
    this.insertRow('15', k);
  }

  insertSalesDraft(newdraft) {
    if (typeof newdraft !== "undefined") {
      let k = this.drafts_data;
      k.push(newdraft);
      this.insertRow('4', k);
    }
  }

  insertReturnDraft(newdraft) {
    if (typeof newdraft !== "undefined") {
      let k = this.return_drafts_data;
      k.push(newdraft);
      this.insertRow('15', k);
    }
  }

  setFilteredSales() {
    let salesSearchFields = "";
    let text = this.salessearchTerm;

    if (this.drafts_processed == null) {
      this.drafts_processed_filtered_data = null;
    } else
      this.drafts_processed_filtered_data = this.drafts_processed.filter(
        (item) => {
          return (
            salesSearchFields
              .concat(
                item.date,
                item.customer.name,
                item.description,
                item.total
              )
              .toLowerCase()
              .indexOf(text.toLowerCase()) > -1
          );
        }
      );

    if (this.deliveries_data == null) {
      this.deliveries_filtered_data = null;
    } else
      this.deliveries_filtered_data = this.deliveries_data.filter((item) => {
        return (
          salesSearchFields
            .concat(
              item.dnote,
              item.customer.name,
              item.customer.id,
              item.dpname,
              item.docentry,
              item.doctotal,
              item.spname,
              item.date
            )
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });

    if (this.invoices_data == null) {
      this.invoices_filtered_data = null;
    } else
      this.invoices_filtered_data = this.invoices_data.filter((item) => {
        return (
          salesSearchFields
            .concat(
              item.dnote,
              item.customer.name,
              item.customer.id,
              item.dpname,
              item.docentry,
              item.doctotal,
              item.spname,
              item.date
            )
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });
  }
  setFilteredReturns() {
    let returnSearchFields = "";
    let text = this.returnsearchTerm;

    if (this.return_processed == null) {
      return null;
    } else
      this.return_processed_filtered_data = this.return_processed.filter(
        (item) => {
          return (
            returnSearchFields
              .concat(
                item.date,
                item.customer.name,
                item.description,
                item.total
              )
              .toLowerCase()
              .indexOf(text.toLowerCase()) > -1
          );
        }
      );

    if (this.returns_data == null) {
      return null;
    } else
      this.return_filtered_data = this.returns_data.filter((item) => {
        return (
          returnSearchFields
            .concat(
              item.dnote,
              item.customer.name,
              item.dpname,
              item.docentry,
              item.doctotal,
              item.spname,
              item.date
            )
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });

    if (this.creditmemos_data == null) {
      return null;
    } else
      this.creditmemos_filtered_data = this.creditmemos_data.filter((item) => {
        return (
          returnSearchFields
            .concat(
              item.dnote,
              item.customer.name,
              item.dpname,
              item.docentry,
              item.doctotal,
              item.spname,
              item.date
            )
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });
  }

  setFilteredCustomers() {
    let customerSearchFields = "";
    let text = this.customersearchTerm;
    if (this.customer_data == null) {
      return null;
    } else
      this.customer_filtered_data = this.customer_data.filter((item) => {
        return (
          customerSearchFields
            .concat(item.id, item.reg, item.name)
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });

    if (this.fav_customers_list == null) {
      return null;
    } else
      this.fav_customers_search_list = this.fav_customers_list.filter(
        (item) => {
          return (
            customerSearchFields
              .concat(item.id, item.reg, item.name)
              .toLowerCase()
              .indexOf(text.toLowerCase()) > -1
          );
        }
      );
  }

  setFilteredItems() {
    let text = this.itemsearchTerm;
    let itemSearchFields = "";
    if (this.item_data == null) {
      return null;
    } else
      this.item_filtered_data = this.item_data.filter((item) => {
        return (
          itemSearchFields
            .concat(item.id, item.name, item.barcode)
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });

    if (this.fav_items_list == null) {
      return null;
    } else
      this.fav_items_search_list = this.fav_items_list.filter((item) => {
        return (
          itemSearchFields
            .concat(item.id, item.name, item.barcode)
            .toLowerCase()
            .indexOf(text.toLowerCase()) > -1
        );
      });
  }

  logout() {
    this.deleteRows('0');
    this.deleteRows('1');
    this.deleteRows('2');
    this.deleteRows('3');
    this.deleteRows('4');
    this.deleteRows('5');
    this.deleteRows('6');
    this.deleteRows('7');
    this.deleteRows('8');
    this.deleteRows('9');
    this.deleteRows('10');
    //this.deleteRows('11');
    //this.deleteRows('12');
    this.deleteRows('13');
    this.deleteRows('14');
    this.deleteRows('15');
    this.deleteRows('16');
    this.deleteRows('17');
    this.deleteRows('30');
    this.deleteRows('31');
    this.deleteRows('32');
    this.deleteRows('33');
    this.deleteRows('34');
    this.deleteRows('91');
    this.deleteRows('92');
    this.deleteRows('93');
    this.storage.set('93', JSON.stringify([93, '{"value":false}']))
    this.deleteRows('94');
    this.cartService.cartC = {};
    this.cartService.cartP = [];
    this.cartService.cartItemCount.next(
      this.cartService.cartItemCount.value -
      this.cartService.cartItemCount.value
    );
    this.cartService.cartDiscountStatus = true;
    this.cartService.cartDiscount = 0;
    this.cartService.cartSalesType = "1";
    this.cartService.cartPaymentTerm = "1";
    this.cartService.cartReturnType = "2";
    this.cartService.cartSourceType = "";
    this.cartService.cartCompactView = false;
    this.cartService.cartStatusFromDP = "";
    this.cartService.cartPaymentStatusFromDP = "";
    this.cartService.cartNoteFromDP = "";
    this.cartService.cartSpcode = "";
    this.cartService.cartDpcode = "";
    this.cartService.cartOwnercode = "";

    this.tracking_state.value == false;
    this.authenticationService.authenticationState.next(false);

    this.gpsdashboard_data = [];
  }

  async presentLogoutConfirm() {
    const alert = await this.alertCtrl.create({
      message: "Программаас гарах уу",
      buttons: [
        {
          text: "Болих",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Гарах",
          handler: () => {
            this.logout();
          },
        },
      ],
    });
    alert.present();
  }
  async showQrCodeKey() {
    const alert = await this.alertCtrl.create({
      header:
        "Доорх QR кодыг уншуулах замаар дараа дахин нэвтрэхдээ ашиглаарай. Screenshot хийж хадгалаарай",
      message:
        '<img src="https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=' +
        this.auth_data?.userkey +
        '">',
      buttons: [
        {
          text: "Хаах",
          role: "cancel",
          handler: () => { },
        },
      ],
    });
    alert.present();
  }
  encodeQr() {
    this.barcodeScanner
      .encode(this.barcodeScanner.Encode.TEXT_TYPE, this.auth_data?.userkey)
      .then(
        (encodedData) => {
          this.encodedqr = encodedData;
          console.log(this.encodedqr);
        },
        (err) => {
          console.log("Error occured : " + err);
        }
      );
  }
}
