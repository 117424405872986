import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  shouldDisable=true;
  authenticationState = new BehaviorSubject(false);
  constructor( ) { }

  isAuthenticated() {
    return this.authenticationState.value;
  }
}
